html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: 600;
}

b {
  font-weight: 500;
}

.font-weight--light {
  font-weight: 300;
}

@font-face {
  font-family: "SFProText-Regular";
  src: local("SFProText-Regular"), url("./assets/fonts/SF-Pro-Text-Regular.otf");
}

@font-face {
  font-family: "SFProText-Bold";
  src: local("SFProText-Bold"), url("./assets/fonts/SF-Pro-Text-Bold.otf");
}

@font-face {
  font-family: "SFProText-Heavy";
  src: local("SFProText-Heavy"), url("./assets/fonts/SF-Pro-Text-Heavy.otf");
}

@font-face {
  font-family: "SFProText-Semibold";
  src: local("SFProText-Semibold"),
    url("./assets/fonts/SF-Pro-Text-Semibold.otf");
}

@font-face {
  font-family: "SFProText-Medium";
  src: local("SFProText-Medium"), url("./assets/fonts/SF-Pro-Text-Medium.otf");
}
